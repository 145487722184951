import React, {FC, useState, useCallback} from 'react';
import {navigate} from 'gatsby';
import Layout from '../layouts/Primary';
import SubHeader from '../components/SubHeader';
import {LogInForm} from '../porter-package/';

const Login: FC = () => {
	const [status, setStatus] = useState<boolean | undefined>(undefined);
	const handleComplete = useCallback(
		(result) => {
			setStatus(true);
			setTimeout(() => {
				window.location.href = '/dashboard';
			}, 2000);
		},
		[status, setStatus]
	);

	switch (status) {
		case undefined:
			return <Layout>
				<SubHeader
					title="Log In"
				/>
				<div className="ex-basic-2">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="text-container">
									<LogInForm
										onComplete={handleComplete} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</Layout>;
		case true:
			return <Layout>
				<SubHeader
					title="Logged In"
				/>
				<div className="ex-basic-2">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="text-container" />
								One moment while we redirect you
							</div>
						</div>
					</div>
				</div>
			</Layout>;
			return null;
		case false:
			return <Layout>
				<SubHeader
					title="Log In"
				/>
				<div className="ex-basic-2">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="text-container" />
								Please login to continue
							</div>
						</div>
					</div>
				</div>
			</Layout >;
	}
};

export default Login;
